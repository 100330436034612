/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import Title from '../title'
import Section from '../section'

import { renderHTML } from '../../lib/helpers'

import { Cont, FlexCols, FlexCol, Space } from '../../styles/els'

const Introduction = (props) => {
  const { title, introduction, ...otherProps } = props

  return (
    <Section>
      <FlexCols>
        <FlexCol base={[1, null, null, 2]}>
          <Title title={title} ignorePB={!introduction} />
        </FlexCol>
        {introduction ? (
          <FlexCol base={[1, null, null, 2]}>
            <div sx={{ variant: 'textBlock.body' }}>
              {renderHTML(introduction)}
            </div>
          </FlexCol>
        ) : null}
      </FlexCols>
      <Space />
    </Section>
  )
}

export default Introduction
