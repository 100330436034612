/** @jsx jsx */
import React, { useRef, useEffect, useState, useMemo } from 'react'
import { jsx } from 'theme-ui'
import { isFinite, isBoolean, get } from 'lodash'
import { css } from '@emotion/react'
import { motion } from 'framer-motion'
import useInView from 'react-cool-inview'
import formatDuration from 'format-duration'
import { useAtom } from 'jotai'
import Hls from 'hls.js'

import Image, { FillContainer, InlineContainer, RatioBox } from '../image'

import { calcRatio } from '../../lib/math-helpers'
import { setTORef, clearTORef } from '../../lib/hooks/use-timeout'

import { fillArea, floodBgImage } from '../../styles/css'
import { crossBrowserStyle } from '../../styles/utils'

import VideoAutoplayCheck, { videoCanAutoplayAtom } from './autoplay-check'
import Icon from './icon'
import InlineVideo from './inline'
import Controls from './controls'

const Video = (props) => {
  const {
    placeholder,
    mux,
    contain,
    hAlign,
    vAlign,
    imageProps,
    paused: _paused,
    muted: _muted,
    loop: _loop,
    controls: _controls,
    tracksScroll: _tracksScroll,
    playsAutomatically: _playsAutomatically,
    ...otherProps
  } = props

  const controls = isBoolean(_controls) ? _controls : false
  const tracksScroll = controls
    ? true
    : isBoolean(_tracksScroll)
    ? _tracksScroll
    : false
  const [playsAutomatically] = useState(
    isBoolean(_playsAutomatically) ? _playsAutomatically && controls : false
  )

  const loop = controls ? false : isBoolean(_loop) ? _loop : true
  const paused = isBoolean(_paused) ? _paused : false
  const [autoplay] = useState(!paused || (tracksScroll && inView))

  const videoRef = useRef(null)
  const { inView, observe, entry } = useInView({
    rootMargin: '25px',
  })
  const videoPlayingTargetRef = useRef(false)
  const hoverTORef = useRef(null)

  const [videoPlaying, setVideoPlaying] = useState(false)
  const [videoIsPlaying, setVideoIsPlaying] = useState(false)
  const [muted, setMuted] = useState(
    isBoolean(_muted) ? _muted : !playsAutomatically
  )
  const [controlsActive, setControlsActive] = useState(false)
  const [hoverActive, setHoverActive] = useState(false)

  const src = mux ? `https://stream.mux.com/${mux}.m3u8` : null

  const targetPlayVideo = () => {
    videoPlayingTargetRef.current = true
    playVideo()
  }

  const targetPauseVideo = (toStart = false) => {
    videoPlayingTargetRef.current = false
    pauseVideo()
    if (toStart) {
      toVideoTime(0)
    }
  }

  const playVideo = () => {
    if (videoPlaying || !videoRef.current) {
      return
    }
    const playPromise = videoRef.current.play()
    if (playPromise) {
      playPromise.then(() => {
        setVideoPlaying(true)
        if (!videoPlayingTargetRef.current) {
          pauseVideo()
        }
      })
    }
  }

  const pauseVideo = () => {
    if (!videoPlaying || !videoRef.current) {
      return
    }
    videoRef.current.pause()
    setVideoPlaying(false)
    if (videoPlayingTargetRef.current) {
      playVideo()
    }
  }

  const toVideoTime = (t) => {
    if (videoRef.current) {
      videoRef.current.currentTime = t
    }
  }

  const initControls = () => {
    setControlsActive(true)
    toVideoTime(0)
    setMuted(false)
  }

  useEffect(() => {
    if (!src) {
      return null
    }

    let hls = null
    let videoFailed = false

    if (videoRef.current) {
      if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
        videoRef.current.src = src
      } else if (Hls.isSupported()) {
        hls = new Hls()
        hls.loadSource(src)
        hls.attachMedia(videoRef.current)
      } else {
        console.warn('Video not supported')
        videoFailed = true
      }
    }

    if (autoplay && !videoFailed) {
      targetPlayVideo()
    }

    return () => {
      if (hls) {
        hls.destroy()
      }
    }
  }, [videoRef, src, autoplay])

  useEffect(() => {
    const onPlay = () => {
      setVideoIsPlaying(true)
    }
    const onPause = () => {
      setVideoIsPlaying(false)
    }
    const removePlayPauseListener = () => {
      if (videoRef && videoRef.current) {
        videoRef.current.removeEventListener('play', onPlay)
        videoRef.current.removeEventListener('pause', onPause)
      }
    }
    const addPlayPauseListener = () => {
      removePlayPauseListener()
      if (videoRef && videoRef.current) {
        videoRef.current.addEventListener('play', onPlay)
        videoRef.current.addEventListener('pause', onPause)
      }
    }

    addPlayPauseListener()
    return () => {
      removePlayPauseListener()
    }
  }, [videoRef])

  useEffect(() => {
    if (videoIsPlaying && playsAutomatically && !controlsActive) {
      setControlsActive(true)
    }
  }, [videoIsPlaying, controlsActive, controls, playsAutomatically])

  useEffect(() => {
    const onEnded = () => {
      targetPauseVideo()
    }
    const removeEndedListener = () => {
      if (videoRef && videoRef.current) {
        videoRef.current.removeEventListener('ended', onEnded)
      }
    }
    const addEndedListener = () => {
      removeEndedListener()
      if (videoRef && videoRef.current && controlsActive) {
        videoRef.current.addEventListener('ended', onEnded)
      }
    }

    addEndedListener()
    return () => {
      removeEndedListener()
    }
  }, [videoRef, controlsActive])

  useEffect(() => {
    let videoPlayState = null
    let videoToStart = false
    const PLAY = 'PLAY'
    const PAUSE = 'PAUSE'

    if (controlsActive || !videoRef.current) {
      videoPlayState = null
    } else if (isBoolean(_paused)) {
      videoPlayState = _paused ? PAUSE : PLAY
    } else if (tracksScroll) {
      videoPlayState = inView ? PLAY : PAUSE
      videoToStart = true
    }

    if (videoPlayState === PLAY) {
      targetPlayVideo()
    } else if (videoPlayState === PAUSE) {
      targetPauseVideo(videoToStart)
    }
  }, [videoRef, inView, tracksScroll, _paused, controlsActive, videoPlaying])

  return (
    <FillContainer
      size={placeholder}
      contain={contain}
      hAlign={hAlign}
      vAlign={vAlign}
      onMouseMove={
        controls
          ? () => {
              clearTORef(hoverTORef)
              setTORef(
                () => {
                  setHoverActive(true)
                },
                50,
                hoverTORef
              )
            }
          : null
      }
      onMouseLeave={
        controls
          ? () => {
              clearTORef(hoverTORef)
              setTORef(
                () => {
                  setHoverActive(false)
                },
                250,
                hoverTORef
              )
            }
          : null
      }
    >
      {tracksScroll ? (
        <div
          ref={observe}
          sx={{ zIndex: 0, pointerEvents: 'none' }}
          css={css`
            ${fillArea}
          `}
        />
      ) : null}
      {controls ? (
        <div
          sx={{
            zIndex: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            '&:hover': {
              cursor: 'pointer',
            },
          }}
          css={css`
            ${fillArea}
          `}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()

            if (!controlsActive) {
              initControls()
            } else if (videoPlaying) {
              targetPauseVideo()
            } else {
              targetPlayVideo()
            }
          }}
        >
          {!controlsActive ? (
            <Icon icon="play" sx={{ width: ['32px', null, null, '62px'] }} />
          ) : null}
        </div>
      ) : null}
      {controlsActive ? (
        <Controls
          videoRef={videoRef}
          onSetTime={(t) => {
            toVideoTime(t)
          }}
          paused={!videoPlaying}
          muted={muted}
          onTogglePlay={() => {
            if (videoPlaying) {
              targetPauseVideo()
            } else {
              targetPlayVideo()
            }
          }}
          onToggleMuted={() => {
            setMuted(!muted)
          }}
          visible={hoverActive || !videoPlaying}
        />
      ) : null}
      {src ? (
        <video
          ref={videoRef}
          preload="metadata"
          playsInline={true}
          muted={muted}
          controls={false}
          loop={loop}
          css={css`
            ${fillArea}
          `}
          sx={{
            zIndex: 1,
          }}
        />
      ) : null}
      {placeholder ? (
        <motion.div
          sx={{ zIndex: 1, pointerEvents: 'none' }}
          css={css`
            ${fillArea}
          `}
          animate={!videoIsPlaying && !controlsActive ? 'in' : 'out'}
          variants={{
            in: {
              opacity: 1,
              transition: {
                duration: 0.4,
              },
            },
            out: {
              opacity: 0,
              transition: {
                duration: 0.15,
              },
            },
          }}
        >
          <Image image={placeholder} {...imageProps} />
        </motion.div>
      ) : null}
    </FillContainer>
  )
}

export { VideoAutoplayCheck, videoCanAutoplayAtom, InlineVideo }

export default Video
