/** @jsx jsx */
import React from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { css } from '@emotion/react'

import { renderHTML } from '../../lib/helpers'
import { VStack } from '../../styles/els'
import { useThemeUISetValue } from '../../styles/utils'
import theme from '../../styles/theme'

const Card = (props) => {
  const { children, expandPadding, bgColor, ...otherProps } = props
  const { theme } = useThemeUI()
  const padding = useThemeUISetValue('cardPadding', theme)

  return (
    <div
      sx={{
        position: 'relative',
        backgroundColor: bgColor ? bgColor : 'grey4',
        p: padding,
        borderRadius: 1,
        mx: expandPadding ? [null, null, null, -7] : null,
        overflow: 'hidden',
      }}
      {...otherProps}
    >
      {children}
    </div>
  )
}

export default Card
