/** @jsx jsx */
import React from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { css } from '@emotion/react'
import { get, isFinite } from 'lodash'

import HeaderLogo from '../header-logo'
import ImageFill from '../image'
import Video from '../video'

import { formatImage, renderHTML } from '../../lib/helpers'
import useScreenSize from '../../lib/hooks/use-screen-size'

import { fillArea } from '../../styles/css'
import { Cont } from '../../styles/els'
import { useThemeUISetValue } from '../../styles/utils'

import PageHeaderWrapper from './wrapper'

const Home = (props) => {
  const { video, videoPlaceholder, tagline } = props

  const screenSize = useScreenSize()

  const { theme } = useThemeUI()

  const p = useThemeUISetValue('sectionPadding', theme)

  const headerHeight =
    isFinite(screenSize.width) && isFinite(screenSize.height)
      ? Math.min(screenSize.width * 0.8, screenSize.height)
      : null

  return (
    <PageHeaderWrapper
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'flex-end',
      }}
      style={{
        minHeight: headerHeight,
      }}
    >
      <div
        sx={{
          position: 'relative',
          zIndex: 11,
          pb: p,
          pt: '200px',
        }}
      >
        {tagline ? (
          <Cont>
            <div sx={{ variant: 'text.heading_64', maxWidth: '920px' }}>
              {renderHTML(tagline)}
            </div>
          </Cont>
        ) : null}
      </div>
      <div
        sx={{
          backgroundColor: 'black',
          opacity: 0.3,
          zIndex: 10,
        }}
        css={css`
          ${fillArea}
        `}
      />
      {video && videoPlaceholder ? (
        <Video mux={video} placeholder={videoPlaceholder} sx={{ zIndex: 1 }} />
      ) : null}
    </PageHeaderWrapper>
  )
}

export default Home
