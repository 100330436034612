/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'

import { RatioBox } from '../image'

import PlayIcon from './play.svg'
import PauseIcon from './pause.svg'
import MuteIcon from './mute.svg'
import VolumeIcon from './volume.svg'

const Icon = (props) => {
  const { icon, ...otherProps } = props

  let iconImg = null

  switch (icon) {
    case 'pause':
      iconImg = PauseIcon
      break
    case 'mute':
      iconImg = MuteIcon
      break
    case 'volume':
      iconImg = VolumeIcon
      break
    default:
      iconImg = PlayIcon
  }

  return (
    <RatioBox
      sx={{
        backgroundImage: `url(${iconImg})`,
      }}
      ratio={1}
      {...otherProps}
    />
  )
}

export default Icon
