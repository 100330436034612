/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { motion } from 'framer-motion'
import { useAtom } from 'jotai'

import SEO from '../seo'
import Footer from '../footer'

import { Cont } from '../../styles/els'

const PageWrapper = (props) => {
  const { children, seo, ...otherProps } = props

  return (
    <React.Fragment>
      <SEO {...seo} />
      <div
        {...otherProps}
        sx={{
          overflowX: 'hidden',
        }}
      >
        {children}
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default PageWrapper
