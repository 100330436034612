/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { isFinite, isArray } from 'lodash'

import { Cont, FlexCols, FlexCol, FlexColEmpty } from '../../styles/els'

const CenterCol = (props) => {
  const { children, span: _span, ...otherProps } = props

  const span = isArray(_span)
    ? _span
    : [12, null, null, isFinite(_span) ? _span : 6]

  return (
    <FlexCols
      sx={{
        justifyContent: 'center',
      }}
      {...otherProps}
    >
      <FlexCol across={span} base={12}>
        {children}
      </FlexCol>
    </FlexCols>
  )
}

export default CenterCol
