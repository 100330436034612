/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import { renderHTML } from '../../lib/helpers'
import { VStack } from '../../styles/els'

const CardType = (props) => {
  const { sub, heading, text, tier, ...otherProps } = props

  const vSpace = tier === 2 ? 2 : 3

  return (
    <VStack space={vSpace} {...otherProps}>
      {sub ? (
        <h6
          sx={{
            variant: tier === 2 ? 'text.regular_16' : 'text.regular_20',
            textTransform: 'lowercase',
          }}
        >
          {sub}
        </h6>
      ) : null}
      {heading ? (
        <h4
          sx={{
            variant:
              tier === 0
                ? 'text.heading_48'
                : tier === 2
                ? 'text.heading_25'
                : 'text.heading_36',
          }}
        >
          {renderHTML(heading)}
        </h4>
      ) : null}
      {text ? (
        <div
          sx={{
            variant: 'textBlock.body',
          }}
        >
          {renderHTML(text)}
        </div>
      ) : null}
    </VStack>
  )
}

export default CardType
