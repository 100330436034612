/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import CenterCol from '../center-col'
import ImageFill, { ImageInline } from '../image'
import HeaderLogo from '../header-logo'
import VideoWrapper from '../video-wrapper'

import { fillArea } from '../../styles/css'
import { Cont, FlexCols, FlexCol, FlexColEmpty } from '../../styles/els'

import PageHeaderWrapper from './wrapper'

const InlineHeader = (props) => {
  const { image, video } = props

  return (
    <PageHeaderWrapper inline={true}>
      <Cont sx={{ pt: 6 }}>
        <CenterCol span={10}>
          {image ? (
            <ImageInline image={image} maxRatio={9 / 16} />
          ) : video ? (
            <VideoWrapper
              videoType={video.videoType}
              embed={video.embed}
              mux={video.mux}
            />
          ) : null}
        </CenterCol>
      </Cont>
    </PageHeaderWrapper>
  )
}

export default InlineHeader
