/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import HeaderLogo from '../header-logo'
import ImageFill, { RatioBox } from '../image'

import { fillArea } from '../../styles/css'
import { Cont } from '../../styles/els'

import PageHeaderWrapper from './wrapper'

const FullHeader = (props) => {
  const { image } = props
  return (
    <PageHeaderWrapper sx={{ minHeight: '480px' }}>
      <Cont>
        <RatioBox
          sx={{
            zIndex: 1,
          }}
          ratio={680 / 1280}
        />
      </Cont>
      <div
        sx={{ zIndex: 11, backgroundColor: 'grey4', opacity: 0.25 }}
        css={css`
          ${fillArea}
        `}
      />
      {image ? (
        <ImageFill
          sx={{
            zIndex: 10,
          }}
          image={image}
        />
      ) : null}
    </PageHeaderWrapper>
  )
}

export default FullHeader
