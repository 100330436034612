import React, { useRef, useCallback, useEffect } from 'react'
import { isObject, isNil, keys, each } from 'lodash'

export const clearTORef = (ref, key = null) => {
  if (ref && ref.current) {
    if (isNil(key) && isObject(ref.current)) {
      each(keys(ref.current), (_k, _i) => {
        clearTimeout(ref.current[_k])
      })
    } else {
      clearTimeout(!isNil(key) ? ref.current[key] : ref.current)
    }
  }
}

export const setTORef = (cb, time = 100, ref, key = null) => {
  const tO = setTimeout(cb, time)
  if (ref && ref.current) {
    if (isObject(ref.current) && !isNil(key)) {
      ref.current[key] = tO
    } else {
      ref.current = tO
    }
  }
}

export default (cb, time = 0, dep = []) => {
  const timeoutIDRef = useRef()

  const cancel = useCallback(() => {
    clearTORef(timeoutIDRef)
  }, [timeoutIDRef])

  useEffect(() => {
    setTORef(cb, time, timeoutIDRef)
    return cancel
  }, [...dep])

  return cancel
}
