/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import CenterCol from '../center-col'
import ImageFill, { ImageInline } from '../image'
import HeaderLogo from '../header-logo'

const PageHeaderWrapper = (props) => {
  const { inline, children, ...otherProps } = props

  return (
    <div
      sx={{
        position: 'relative',
        backgroundColor: 'grey4',
        width: '100%',
        zIndex: 1,
      }}
      {...otherProps}
    >
      <HeaderLogo inline={inline} />
      {children}
    </div>
  )
}

export default PageHeaderWrapper
