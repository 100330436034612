/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { isArray } from 'lodash'

import Section from '../section'

import { renderHTML } from '../../lib/helpers'

import { Cont, FlexCols, FlexCol } from '../../styles/els'

const Title = (props) => {
  const { title, sub, subLower, backTo, color, ignorePB, ...otherProps } = props

  return (
    <div {...otherProps}>
      {sub && sub.length > 0 ? (
        <h3
          sx={{
            pb: 1,
            color: color ? color : null,
          }}
        >
          {isArray(sub)
            ? sub.map((_subPart, _i) => {
                return (
                  <span
                    key={_i}
                    sx={{
                      variant: _i === 0 ? 'text.bold_25' : 'text.regular_25',
                      textTransform: subLower ? 'lowercase' : null,
                      pl: _i > 0 ? 2 : null,
                    }}
                  >
                    {_subPart}
                  </span>
                )
              })
            : sub}
        </h3>
      ) : null}
      <h1
        sx={{
          variant: 'text.heading_64',
          pb: ignorePB ? null : 7,
        }}
      >
        {renderHTML(title)}
      </h1>
    </div>
  )
}

export default Title
