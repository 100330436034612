/** @jsx jsx */
import React, { useMemo } from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { motion } from 'framer-motion'
import { useAtom } from 'jotai'
import { graphql, useStaticQuery } from 'gatsby'
import { get } from 'lodash'
import { DateTime } from 'luxon'

import Logo from '../logo'
import Icon from '../icon'

import { Cont, FlexCols, FlexCol, VStack, HStack } from '../../styles/els'
import Link from '../link'
import { LinkTypes } from '../../lib/helpers'
import { useThemeUISetValue } from '../../styles/utils'

const query = graphql`
  query {
    pages: allWpPage(
      sort: { order: ASC, fields: menuOrder }
      filter: { acfPage: { navigationLevel: { ne: "hidden" } } }
    ) {
      nodes {
        title
        uri
        parentId
      }
    }
  }
`

const socialLinks = [
  {
    icon: 'youtube',
    to: 'https://www.youtube.com/synergytheatreproject',
  },
  {
    icon: 'twitter',
    to: 'https://twitter.com/Synergy_Theatre',
  },
  {
    icon: 'instagram',
    to: 'https://www.instagram.com/synergytheatreproject/',
  },
]

const FooterTitle = (props) => {
  const { label } = props

  return <h6 sx={{ variant: 'text.mono', pb: 4, color: 'grey1' }}>{label}</h6>
}

const Footer = (props) => {
  const year = useMemo(() => {
    return DateTime.now().toFormat('yyyy')
  }, [])
  const data = useStaticQuery(query)
  const links = get(data, 'pages.nodes')

  const { theme } = useThemeUI()

  const pT = useThemeUISetValue('headingPadding', theme)
  const logoColBase = [1, null, null, 3]
  const colBase = [1, 2, null, 3]
  const pb = [7, null, null, 8]

  return (
    <footer
      sx={{
        pt: pT,
        pb: pb,
        mt: 1,
        backgroundColor: 'grey4',
        variant: 'text.regular_16',
        color: 'grey1',
      }}
    >
      <Cont>
        <FlexCols>
          <FlexCol base={logoColBase}>
            <Logo
              sx={{
                maxWidth: '120px',
                opacity: 0.5,
                mb: 4,
              }}
            />
          </FlexCol>
          <FlexCol base={colBase}>
            <FooterTitle label="Navigation" />
            {links && links.length > 0 ? (
              <ul>
                {links.map((_link, _i) => {
                  return _link.parentId ? null : (
                    <li key={_i}>
                      <Link to={_link.uri}>{_link.title}</Link>
                    </li>
                  )
                })}
              </ul>
            ) : null}
          </FlexCol>
          <FlexCol base={colBase}>
            <FooterTitle label="Contact" />
            <VStack>
              <p>
                Synergy Theatre Project
                <br />
                Brixton House
                <br />
                G.1 Carlton Mansions
                <br />
                387 Coldharbour Lane
                <br />
                London SW9 8GL
              </p>
              <ul>
                <li>
                  <a href="mailto:info@synergytheatreproject.co.uk">
                    info@synergytheatreproject.co.uk
                  </a>
                </li>
                <li>+44 (0) 20 3034 0787</li>
              </ul>
              <HStack>
                {socialLinks.map((_link, _i) => {
                  return (
                    <Link
                      to={_link.to}
                      type={LinkTypes.EXTERNAL}
                      sx={{ display: 'block', width: '32px', opacity: 0.4 }}
                      key={_i}
                    >
                      <Icon icon={_link.icon} />
                    </Link>
                  )
                })}
              </HStack>
            </VStack>
          </FlexCol>
        </FlexCols>
        <HStack sx={{ pt: pb, color: 'grey1' }}>
          <Link
            to="/pdfs/synergy_safeguarding-policy_jan24.pdf"
            type={LinkTypes.EXTERNAL}
          >
            Safeguarding Policy
          </Link>
          <Link
            to="/pdfs/synergy_vulnerable-adults_23.pdf"
            type={LinkTypes.EXTERNAL}
          >
            Vulnerable Adults Policy
          </Link>
        </HStack>
        <HStack sx={{ pt: pb, color: 'grey2' }}>
          <div>{`© ${year} Synergy Theatre Project`}</div>
          <div>Registered Charity Nº 1088692</div>
          <div>Company Nº 04219146</div>
        </HStack>
      </Cont>
    </footer>
  )
}

export default Footer
