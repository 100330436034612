/** @jsx jsx */
import React from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { css } from '@emotion/react'
import { isFinite, get } from 'lodash'
import { graphql, useStaticQuery } from 'gatsby'

import Link from '../link'
import Logo from '../logo'

import { Cont, FlexCols, FlexCol, FlexColEmpty } from '../../styles/els'
import { useThemeUISetValue } from '../../styles/utils'

const LogoInner = () => {
  const { theme } = useThemeUI()
  const pT = useThemeUISetValue('headerPT', theme)

  return (
    <Cont sx={{ pt: pT }}>
      <Link
        to="/"
        sx={{
          display: 'block',
          width: ['100%', null, '25%'],
          maxWidth: ['120px', null, '180px', null, '200px', '240px'],
        }}
      >
        <Logo />
      </Link>
    </Cont>
  )
}

const HeaderLogo = (props) => {
  const { inline } = props
  return inline ? (
    <LogoInner />
  ) : (
    <div
      sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        zIndex: 2,
      }}
    >
      <LogoInner />
    </div>
  )
}

export default HeaderLogo
