import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { get, find, isNil } from 'lodash'
import { useStaticQuery, graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

import { ThumbnailImage } from '../../lib/fragments'

const query = graphql`
  query {
    homepage: allWpPage(filter: { isFrontPage: { eq: true } }, limit: 1) {
      nodes {
        seo {
          title
          metaDesc
          opengraphImage {
            ...ThumbnailImage
          }
        }
      }
    }
  }
`

export const getSEOFromPage = (pageNode) => {
  const title = get(pageNode, 'seo.title')
  const description = get(pageNode, 'seo.metaDesc')
  const image = getImageSrc(get(pageNode, 'seo.opengraphImage'))
  return { title, description, image }
}

const getImageSrc = (file) => {
  return !file
    ? null
    : getSrc(get(file, 'localFile.childImageSharp.gatsbyImageData'))
}

const getSEOValue = (values) => {
  return find(values, (v) => !isNil(v) && v.length > 0)
}

const SEO = (props) => {
  const {
    title: _title,
    description: _description,
    image: _image,
    pageNode,
  } = props

  const defaultData = useStaticQuery(query)

  const [title, description, image] = useMemo(() => {
    const homepageData = get(defaultData, 'homepage.nodes.0')
    const defaultSEO = getSEOFromPage(homepageData)
    const pageSEO = getSEOFromPage(pageNode)

    const title = getSEOValue([pageSEO.title, _title, defaultSEO.title])
    const description = getSEOValue([
      pageSEO.description,
      _description,
      defaultSEO.description,
    ])
    const image = getSEOValue([pageSEO.image, _image, defaultSEO.image])

    return [
      title,
      description,
      image && process.env.GATSBY_FRONTEND_URL
        ? `${process.env.GATSBY_FRONTEND_URL}${image}`
        : image
        ? image
        : null,
    ]
  }, [defaultData, _title, _description, _image, pageNode])

  return (
    <>
      {title ? (
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
        </Helmet>
      ) : null}
      {description ? (
        <Helmet>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
        </Helmet>
      ) : null}
      {image ? (
        <Helmet>
          <meta property="og:image" content={image} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      ) : null}
    </>
  )
}

export default SEO
